import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "#D2D2D6",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "inherit",
      d: "M2.66669 14V6L8.00002 2L13.3334 6V14H9.33335V9.33333H6.66669V14H2.66669ZM4.00002 12.6667H5.33335V8H10.6667V12.6667H12V6.66667L8.00002 3.66667L4.00002 6.66667V12.6667Z"
    }, null, -1)
  ])))
}
export default { render: render }